import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"


function Service() {
	return (
        
        <div class="services">
            <div className="title">
                  <span>What we Offer?</span>
                  <h1>Explore Our Top Notch Services</h1>

                  
            </div>
            <div className="row">
                <div className="col-sm-3 col-md-6 col-lg-3 first">
                <container>
                <i class="fa fa-life-bouy"></i>
                <h1>Amazing Tours</h1>
                <p>D-Golden tours has mandate and that is make our guests happy and have an adventure of a lifetime...</p>
                <a href="/all-tours">Learn More +</a>
                </container>
                </div>
                <div className="col-sm-3 col-md-6 col-lg-3 second"></div>
                <div className="col-sm-3 col-md-6 col-lg-3 third">
                    <container>
                    <i class="fa fa-bus"></i>
                    <h1>Charters</h1>
                <p>Need a Ride? D-Golden tours offers charters starting as low as 25 USD to various destinations of choice...</p>
                <a href="/charter-tours">Learn More +</a>
                    </container>
                    </div>
                <div className="col-sm-3 col-md-6 col-lg-3 fourth"></div>
            </div>
            <div className="row">
                <div className="col-sm-3 col-md-6 col-lg-3 fifth"></div>
                <div className="col-sm-3 col-md-6 col-lg-3 sixth">
                    <container>
                    <i class="fa fa-gift" aria-hidden="true"></i>
                    <h1>Gift Shop</h1>
                         <p>Check out our store while you are in Hopkins.  Gaurenteed friendly service.  Our store is full of crafts and artwork from local artists and...</p>
                         <a href="/gift-shop">Learn More +</a>
                    </container>
                    
                    </div>
                <div className="col-sm-3 col-md-6 col-lg-3 seventh"></div>
                <div className="col-sm-3 col-md-6 col-lg-3 eight">
                    <container>
                    <i class="fa fa-diamond"></i>
                    <h1>Top Notch Guides</h1>
                         <p>Our experienced guides are full of local knowledge that has been handed down through generations and are ready to share this with you...</p>
                         <a href="/about-us">Learn More +</a>
                    </container>
                    </div>
            </div>
         </div>

    );
}

export default Service;                  